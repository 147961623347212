import React from "react";

const Page404 = ({ }) => {
    return (
        <div className="404-page">

        </div>
    );
};

export default Page404;